body {
  margin: 0;
  background: #f2f1f1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #12b860;
}

.leaflet-div-icon {
  border-radius: 10px;
  width: 15px !important;
  height: 15px !important;
  margin-left: -7px !important;
  margin-top: -7px !important;
}
