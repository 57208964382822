*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
#root,
main {
  width: 100%;
  height: 100%;
  min-height: 540px;
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: rgba(59, 204, 151, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(59, 204, 151, 1) 0%,
    rgba(59, 204, 127, 1) 10%,
    rgba(59, 204, 168, 1) 30%,
    rgba(88, 179, 101, 1) 49%,
    rgba(59, 204, 168, 1) 65%,
    rgba(59, 204, 156, 1) 70%,
    rgba(117, 137, 12, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(59, 204, 151, 1)),
    color-stop(10%, rgba(59, 204, 127, 1)),
    color-stop(30%, rgba(59, 204, 168, 1)),
    color-stop(49%, rgba(88, 179, 101, 1)),
    color-stop(65%, rgba(59, 204, 168, 1)),
    color-stop(70%, rgba(59, 204, 156, 1)),
    color-stop(100%, rgba(117, 137, 12, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(59, 204, 151, 1) 0%,
    rgba(59, 204, 127, 1) 10%,
    rgba(59, 204, 168, 1) 30%,
    rgba(88, 179, 101, 1) 49%,
    rgba(59, 204, 168, 1) 65%,
    rgba(59, 204, 156, 1) 70%,
    rgba(117, 137, 12, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(59, 204, 151, 1) 0%,
    rgba(59, 204, 127, 1) 10%,
    rgba(59, 204, 168, 1) 30%,
    rgba(88, 179, 101, 1) 49%,
    rgba(59, 204, 168, 1) 65%,
    rgba(59, 204, 156, 1) 70%,
    rgba(117, 137, 12, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(59, 204, 151, 1) 0%,
    rgba(59, 204, 127, 1) 10%,
    rgba(59, 204, 168, 1) 30%,
    rgba(88, 179, 101, 1) 49%,
    rgba(59, 204, 168, 1) 65%,
    rgba(59, 204, 156, 1) 70%,
    rgba(117, 137, 12, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(59, 204, 151, 1) 0%,
    rgba(59, 204, 127, 1) 10%,
    rgba(59, 204, 168, 1) 30%,
    rgba(88, 179, 101, 1) 49%,
    rgba(59, 204, 168, 1) 65%,
    rgba(59, 204, 156, 1) 70%,
    rgba(117, 137, 12, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3bcc97', endColorstr='#75890c', GradientType=1 );
}
.account {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: row;
  background-color: #fff;
  border: 0px solid #000000;

  .account__photo {
    background: url('../../../assets/img/banner.jpeg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    .account_Photo_img {
      background: #1b9c309d;
      width: 100%;
      height: 100%;
    }
  }

  .account__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    margin: 0;

    .account__head {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .account__logo__img {
        position: relative;
        float: left;
        width: 379px;
        height: 78px;
        filter: brightness(52%);
      }
      .account__title {
        margin: 7px 0px;
        .account__logo__text {
          font-weight: 350;
          color: #087408;
          font-size: 1.5em;
        }
      }
    }

    .form {
      margin-bottom: 10px;
      width: 100%;
      @media (min-width: 640px) {
        padding: 0rem 6rem;
      }
      font-family: Courier, Arial, Helvetica, sans-serif;

      .form__form-group {
        margin-bottom: 10px;
      }
      .form__form-group-label {
        margin-bottom: 4px;
        margin-bottom: 10px;
        color: #696f79;
      }
    }
  }
}

.account__btns {
  position: relative;
  margin: 10px 0px;
  width: 100%;
  min-width: 150px;
  background-color: #67ae1c;
  border-radius: 0.1rem;

  .btn {
    color: white;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-family: Courier, Arial, Helvetica, sans-serif;
    padding: 0.7rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    &:disabled {
      opacity: 0.65;
    }
    &:focus,
    &:active,
    &:hover {
      outline: none;
      border-radius: 1rem;
      cursor: pointer;
    }
    width: 100%;
    margin-right: 0;
  }
}
a {
  margin-top: 20px;
  font-style: inherit;
  color: #006400;
  font-size: 0.7em;
  font-weight: 500;
}
